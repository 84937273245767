.list-item-text {
  & p {
    margin-top: 0rem;
  }

  & a {
    color: #ff8a4c;
  }

  & li {
    list-style-type: disc;
    list-style-position: inside;
  }

  & h1 {
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 1.75rem;
    margin-top: 2rem;
    font-size: 2.5rem;
  }

  & h2 {
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 1.75rem;
    margin-top: 2rem;
    font-size: 1.875rem;
  }

  & h3 {
    color: #4b5563;
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 1.75rem;
    margin-top: 2rem;
    font-size: 1.875rem;
  }
}