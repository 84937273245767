@use "variables";

*, *::after, *::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;

}

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre {
  overflow: auto;
  padding: 15px;
  margin-bottom: 0;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  text-decoration: none;
}

hr {
  position: relative;
  display: block;
  height: 1px;
  margin: 60px 0;
  border: 0;

  &::before {
    content: ". . .";
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    line-height: 0;
  }
}

.table-container {
  display: block;
  max-width: 100%;
  overflow-x: auto;
}

table {
  font-size: 12px;
  width: 100%;
}

table th {
  padding: 10px;
  font-size: 16px;
  text-align: left;
  font-weight: 700;
}

table tr {
  transition: all .3s ease;
  &:nth-child(odd) {
    background-color: transparent;
  }
}

table td {
  padding: 10px;
  font-size: 14px;
}

.lazy {
  opacity: 0;
  transition: 0.8s ease-in-out;
}

.lazy.loaded {
  opacity: 1;
}